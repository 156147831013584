var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"evaluation"},[_c('div',{staticClass:"regionTopImg",style:({ backgroundImage: `url(${_vm.bannerLogo})` })}),_c('div',{staticClass:"region"},[_c('div',{staticClass:"contentRegion"},_vm._l((_vm.tableData),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"itemLeft"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.completeGaugeCount)+"/"+_vm._s(item.gaugeCount))]),(item.endTime)?_c('p',[_c('img',{staticStyle:{"width":"15px","margin-right":"5px"},attrs:{"src":require("../../assets/home/time.png"),"alt":""}}),_vm._v(_vm._s(item.endTime)+" ")]):_vm._e(),_c('p',[(item.priceType === 1)?_c('span',[(item.discountPrice)?[_vm._v("原价: "),_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v("￥"+_vm._s(item.originalPrice))]),_vm._v("  折扣价: "),_c('span',{staticClass:"redPrice"},[_vm._v("￥"+_vm._s(item.discountPrice))])]:[_vm._v(" 价格："),_c('span',{staticClass:"redPrice"},[_vm._v("￥"+_vm._s(item.originalPrice))])]],2):_vm._e()])]),_c('div',{staticClass:"itemRight"},[(item.isFirst === 0)?_c('button',{on:{"click":function($event){return _vm.todo(item)}}},[_vm._v("继续")]):(item.completeGaugeCount === 0)?_c('button',{on:{"click":function($event){return _vm.todo(item)}}},[_vm._v(" 开始 ")]):(
                item.completeGaugeCount === item.gaugeCount &&
                item.isRepeat === 0
              )?_c('button',{staticClass:"completed"},[_vm._v(" 已完成 ")]):(
                item.completeGaugeCount === item.gaugeCount &&
                item.isRepeat === 1
              )?_c('button',{on:{"click":function($event){return _vm.todo(item)}}},[_vm._v(" 再次测评 ")]):(item.completeGaugeCount < item.gaugeCount)?_c('button',{on:{"click":function($event){return _vm.todo(item)}}},[_vm._v(" 继续 ")]):_vm._e()]),(
              _vm.settingInfo.payment === true || _vm.settingInfo.payment === 'true'
            )?_c('div',{staticClass:"img"},[(item.priceType === 0)?_c('img',{attrs:{"src":require("../../assets/home/free.png"),"alt":""}}):(item.ordersStatus === 0)?_c('img',{attrs:{"src":require("../../assets/home/pay.png"),"alt":""}}):(item.ordersStatus === 1)?_c('img',{attrs:{"src":require("../../assets/home/purchased.png"),"alt":""}}):_vm._e()]):_vm._e()])}),0),(_vm.tableData.length > 0)?_c('div',{staticClass:"toBottom"},[_vm._v("没有更多了...")]):_c('div',{staticClass:"toBottom"},[_vm._v("无可测评计划")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }