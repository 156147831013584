<template>
  <div class="evaluation">
    <!-- 心理测评 测评计划列表 -->
    <div
      class="regionTopImg"
      :style="{ backgroundImage: `url(${bannerLogo})` }"
    >
      <!-- <img :src="bannerUrl" alt=""> -->
    </div>
    <div class="region">
      <!-- <div class="regionTopImg">
                <img src="../../assets/home/banner.jpg" alt="">
            </div> -->
      <div class="contentRegion">
        <div class="item" v-for="item in tableData" :key="item.id">
          <div class="itemLeft">
            <p class="title">{{ item.name }}</p>
            <p>{{ item.completeGaugeCount }}/{{ item.gaugeCount }}</p>
            <p v-if="item.endTime">
              <img
                src="../../assets/home/time.png"
                alt=""
                style="width: 15px; margin-right: 5px"
              />{{ item.endTime }}
            </p>
            <p>
              <!-- <span v-if="item.priceType === 0">免费</span> -->
              <span v-if="item.priceType === 1">
                <template v-if="item.discountPrice"
                  >原价:
                  <span style="text-decoration: line-through"
                    >￥{{ item.originalPrice }}</span
                  >
                  &nbsp;折扣价:
                  <span class="redPrice"
                    >￥{{ item.discountPrice }}</span
                  ></template
                >
                <template v-else>
                  价格：<span class="redPrice">￥{{ item.originalPrice }}</span>
                </template>
                <!-- <span v-if="item.ordersStatus === 0">未支付</span>
                            <span v-if="item.ordersStatus === 1">已支付</span> -->
                <!-- <span v-if="item.ordersStatus === 0">无订单</span>
                            <span v-if="item.ordersStatus === 1">未支付</span>
                            <span v-if="item.ordersStatus === 2">已完成</span>
                            <span v-if="item.ordersStatus === 3">已取消</span> -->
              </span>
            </p>
          </div>
          <div class="itemRight">
            <button v-if="item.isFirst === 0" @click="todo(item)">继续</button>
            <button
              v-else-if="item.completeGaugeCount === 0"
              @click="todo(item)"
            >
              开始
            </button>
            <button
              v-else-if="
                item.completeGaugeCount === item.gaugeCount &&
                item.isRepeat === 0
              "
              class="completed"
            >
              已完成
            </button>
            <button
              v-else-if="
                item.completeGaugeCount === item.gaugeCount &&
                item.isRepeat === 1
              "
              @click="todo(item)"
            >
              再次测评
            </button>
            <button
              v-else-if="item.completeGaugeCount < item.gaugeCount"
              @click="todo(item)"
            >
              继续
            </button>
          </div>
          <div
            class="img"
            v-if="
              settingInfo.payment === true || settingInfo.payment === 'true'
            "
          >
            <img
              src="../../assets/home/free.png"
              alt=""
              v-if="item.priceType === 0"
            />
            <img
              src="../../assets/home/pay.png"
              alt=""
              v-else-if="item.ordersStatus === 0"
            />
            <img
              src="../../assets/home/purchased.png"
              alt=""
              v-else-if="item.ordersStatus === 1"
            />
          </div>
        </div>
      </div>
      <div class="toBottom" v-if="tableData.length > 0">没有更多了...</div>
      <div class="toBottom" v-else>无可测评计划</div>
    </div>
  </div>
</template>

<script>
import { getEvaluationList } from "@/api/evaluation";
import { getSetting } from "@/api/setting";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      userId: null,
      bannerLogo: null,
      settingInfo: null,
    };
  },
  methods: {
    ...mapActions(["getInfo"]),
    todo(val) {
      if (
        this.settingInfo.payment === true ||
        this.settingInfo.payment === "true"
      ) {
        this.$router.push("/home/planDetail");
      } else {
        // this.$router.push('/home/gauge')
        if (val.isMerge === 1) {
          this.$router.push({
            path: "/home/guidelines",
          });
        } else {
          this.$router.push({
            path: "/home/gauge",
          });
        }
      }

      // console.log(val)

      localStorage.setItem("planInfo", JSON.stringify(val));
    },
    // 获取计划列表
    async getList() {
      const res = await getEvaluationList(this.userId);
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data;
      }
    },
    async getSettingInfo() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
  },
  created() {
    this.bannerLogo = sessionStorage.getItem("banner");
    if (
      localStorage.getItem("patientId") !== "null" &&
      localStorage.getItem("patientId")
    ) {
      this.userId = localStorage.getItem("patientId");
    } else {
      this.userId = JSON.parse(localStorage.getItem("userInfo")).id;
    }
    // console.log(this.userId);
    this.getSettingInfo();
    this.getList();

    localStorage.setItem("path", "/home/evaluation");
  },
};
</script>

<style lang="scss" scoped>
.evaluation {
  .regionTopImg {
    img {
      height: 100%;
    }
  }
  // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
  .toBottom {
    padding-top: 25px;
    box-sizing: border-box;
    height: 60px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #7d7d7d;
  }

  //  background-image: url('../../assets/home/backgroundImage.png');
  // background-repeat: no-repeat;
  // background-position: center;
  // background-attachment: fixed;
  .contentRegion {
    background: white;
    padding: 20px 0px;

    .item {
      margin: auto;
      // box-shadow: 0px 2px 4px 0px rgba(148, 146, 146, 0.25);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.17);
      height: 165px;
      width: 650px;
      margin-bottom: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      box-sizing: border-box;
      position: relative;

      .redPrice {
        color: rgba(191, 28, 28, 1);
        // font-size: 22px;
      }

      .img {
        position: absolute;
        right: 0px;
        top: 0px;

        img {
          width: 50px;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        color: #7d7d7d;
        margin-top: 10px;
      }

      .title {
        // width: 300px;
        margin: 0px;
        font-size: 16px;
        font-weight: 400;
        color: #303030;
        // overflow: hidden; //超出的文本隐藏

        // text-overflow: ellipsis; //溢出用省略号显示

        // display: -webkit-box;

        // -webkit-line-clamp: 1; // 超出多少行

        // -webkit-box-orient: vertical;
      }

      .itemRight {
        button {
          cursor: pointer;
          border: none;
          width: 90px;
          height: 35px;
          background: var(--custom-color);
          border-radius: 6px;
          color: white;
          font-size: 14px;
        }

        .completed {
          opacity: 0.5;
          background: #f08457;
        }
      }
    }
  }
}

@media screen and (min-width: 751px) {
  // .evaluation {
  //     // background-image: url('../../assets/home/backgroundImage.png');
  //     // background-repeat: no-repeat;
  //     // background-position: center;
  //     // background-attachment: fixed;
  //     // background-size: cover;
  //     // display: flex;
  //     // justify-content: center;
  // }
  .regionTopImg {
    height: 200px;
    // background-image: url('../../assets/home/banner.png');
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .region {
    // width: 900px;
    // height: 100%;
    background-color: white;

    .redPrice {
      // color: rgba(191, 28, 28, 1);
      font-size: 20px;
    }

    .title {
      width: 450px;
    }
  }
}

@media screen and (max-width: 750px) {
  .regionTopImg {
    height: 150px;
    // background-image: url('../../assets/home/banner.png');
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    // img {
    //     height: 150px;
    //     width: 100%;
    // }
  }
  .region {
    width: 100%;

    .contentRegion {
      background: white;

      .item {
        width: 80%;
        // position: relative;
        padding: 10px 15px;

        .title {
          width: 165px;
        }
      }
    }
  }
}
</style>